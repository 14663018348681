import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// -------------------
const initialState = {
  outlet: [],
  selectedOutlet: '',
};

const slice = createSlice({
  name: 'outlet',
  initialState,
  reducers: {
    addOutlet(state, action) {
      state.outlet = action.payload;
    },
    selectOutlet(state, action) {
      state.selectedOutlet = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export const { addOutlet, selectOutlet } = slice.actions;

// ----------------------------------------------------------------------

const fetchingOutletUser = async (user) => {
  try {
    const payload = {
      userId: user.id,
    };
    const response = await axios('api/user/outlet', { params: payload });
    return response?.data;
  } catch (error) {
    console.error(error);
    return [];
  }
};

const getOutlets = async (user) => {
  const checkOutlet = await fetchingOutletUser(user);
  // console.log('checkout', checkOutlet);
  let res = [];
  if (checkOutlet.length > 1 && user.role_id <= 2) {
    const payload = {
      outletId: user.role_id >= 3 ? user.outlet.outlet_id : null,
    };
    res = await axios
      .get('api/outlet', { params: payload })
      .then((res) => {
        const data = res?.data;
        return data || [];
      })
      .catch((err) => {
        console.error(err);
        return [];
      });
  } else {
    let outlets = [];
    checkOutlet.map((item) =>
      outlets.push({
        id: item.outlet.id,
        name: item.outlet.name,
      })
    );
    res = outlets;
  }
  return res;
};
export function getOutlet(user) {
  return async () => {
    const response = await getOutlets(user);

    dispatch(slice.actions.addOutlet(response));
    dispatch(slice.actions.selectOutlet(response[0].id));
  };
}

export function clearOutlet() {
  return async () => {
    dispatch(slice.actions.selectOutlet(''));
  };
}
