// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    // app: path(ROOTS_DASHBOARD, '/app'),
    // ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    // analytics: path(ROOTS_DASHBOARD, '/analytics'),
    // banking: path(ROOTS_DASHBOARD, '/banking'),
    // booking: path(ROOTS_DASHBOARD, '/booking'),
  },
  // mail: {
  //   root: path(ROOTS_DASHBOARD, '/mail'),
  //   all: path(ROOTS_DASHBOARD, '/mail/all'),
  // },
  // chat: {
  //   root: path(ROOTS_DASHBOARD, '/chat'),
  //   new: path(ROOTS_DASHBOARD, '/chat/new'),
  //   conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey'),
  // },
  // calendar: path(ROOTS_DASHBOARD, '/calendar'),
  // kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account'),
    absen: path(ROOTS_DASHBOARD, '/user/absen'),
  },
  // eCommerce: {
  //   root: path(ROOTS_DASHBOARD, '/e-commerce'),
  //   shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
  //   product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
  //   productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
  //   list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
  //   newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
  //   editById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-blazer-low-77-vintage/edit'),
  //   checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
  //   invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
  // },
  // blog: {
  //   root: path(ROOTS_DASHBOARD, '/blog'),
  //   posts: path(ROOTS_DASHBOARD, '/blog/posts'),
  //   post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
  //   postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
  //   newPost: path(ROOTS_DASHBOARD, '/blog/new-post'),
  // },
  transaksi: {
    root: path(ROOTS_DASHBOARD, '/transaksi'),
  },
  kasir: {
    root: path(ROOTS_DASHBOARD, '/kasir'),
  },
  toko: {
    main: path(ROOTS_DASHBOARD, '/toko'),
    root: path(ROOTS_DASHBOARD, '/toko/list'),
    posts: path(ROOTS_DASHBOARD, '/toko/new-post'),
    edit: path(ROOTS_DASHBOARD, '/toko/1/edit/'),
  },
  categoryPro: {
    main: path(ROOTS_DASHBOARD, '/category'),
    root: path(ROOTS_DASHBOARD, '/category/list'),
  },
  subCategoryPro: {
    main: path(ROOTS_DASHBOARD, '/sub-category'),
    root: path(ROOTS_DASHBOARD, '/sub-category/list'),
  },
  product: {
    main: path(ROOTS_DASHBOARD, '/produk'),
    stock: path(ROOTS_DASHBOARD, '/ms-in-store'),
    inventory: path(ROOTS_DASHBOARD, '/inventory-store'),
    listCode: path(ROOTS_DASHBOARD, '/list-code'),
  },
  member: {
    main: path(ROOTS_DASHBOARD, '/member'),
  },
  diskon: {
    main: path(ROOTS_DASHBOARD, '/diskon'),
  },
  diskonDate: {
    main: path(ROOTS_DASHBOARD, '/discount-date'),
  },
  setting: {
    role: path(ROOTS_DASHBOARD, '/role'),
    updateQty: path(ROOTS_DASHBOARD, '/update-qty'),
  },
  oprational: {
    main: path(ROOTS_DASHBOARD, '/oprationals'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
