import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            { path: 'list', element: <UserList /> },
            { path: 'new', element: <UserCreate /> },
            { path: ':name/edit', element: <UserCreate /> },
            { path: 'account', element: <UserAccount /> },
            { path: 'absen', element: <UserAbsen /> },
          ],
        },
        {
          path: 'transaksi',
          children: [
            { element: <TransaksiHistory />, index: true },
            { path: ':name/edit', element: <TransaksiEdit /> },
          ],
        },
        {
          path: 'kasir',
          children: [{ element: <Kasir />, index: true }],
        },
        {
          path: 'toko',
          children: [
            { element: <Navigate to="/dashboard/toko/list" replace />, index: true },
            { path: 'list', element: <TokoList /> },
            { path: 'new-post', element: <TokoNew /> },
            { path: ':id/edit', element: <TokoEdit /> },
          ],
        },
        {
          path: 'category',
          children: [
            { element: <Navigate to="/dashboard/category/list" replace />, index: true },
            { path: 'list', element: <CategoryList /> },
          ],
        },
        {
          path: 'sub-category',
          children: [
            { element: <Navigate to="/dashboard/sub-category/list" replace />, index: true },
            { path: 'list', element: <SubCategoryList /> },
          ],
        },
        {
          path: 'produk',
          children: [{ element: <Product />, index: true }],
        },
        {
          path: 'ms-in-store',
          children: [{ element: <MsProdukStore />, index: true }],
        },
        {
          path: 'role',
          children: [{ element: <RoleList />, index: true }],
        },
        {
          path: 'update-qty',
          children: [{ element: <UpdateQty />, index: true }],
        },
        {
          path: 'inventory-store',
          children: [{ element: <InventoryStore />, index: true }],
        },
        {
          path: 'list-code',
          children: [{ element: <ListCode />, index: true }],
        },
        {
          path: 'member',
          children: [{ element: <Member />, index: true }],
        },
        {
          path: 'diskon',
          children: [{ element: <Diskon />, index: true }],
        },
        {
          path: 'discount-date',
          children: [{ element: <DiskonDate />, index: true }],
        },
        {
          path: 'oprationals',
          children: [{ element: <Oprational />, index: true }],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
// Dashboard
const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const UserAbsen = Loadable(lazy(() => import('../pages/dashboard/UserAbsen')));
const TransaksiHistory = Loadable(lazy(() => import('../pages/dashboard/TransaksiHistory')));
const TransaksiEdit = Loadable(lazy(() => import('../pages/dashboard/TransaksiEdit')));
const Kasir = Loadable(lazy(() => import('../pages/dashboard/Kasir')));
const TokoList = Loadable(lazy(() => import('../pages/dashboard/TokoList')));
const TokoNew = Loadable(lazy(() => import('../pages/dashboard/TokoNew')));
const TokoEdit = Loadable(lazy(() => import('../pages/dashboard/TokoEdit')));
const CategoryList = Loadable(lazy(() => import('../pages/dashboard/CategoryList')));
const SubCategoryList = Loadable(lazy(() => import('../pages/dashboard/SubCategoryList')));
const Product = Loadable(lazy(() => import('../pages/dashboard/Product')));
const MsProdukStore = Loadable(lazy(() => import('../pages/dashboard/MsProdukStore')));
const InventoryStore = Loadable(lazy(() => import('../pages/dashboard/InventoryStore')));
const Member = Loadable(lazy(() => import('../pages/dashboard/MemberList')));
const Diskon = Loadable(lazy(() => import('../pages/dashboard/DiskonList')));
const DiskonDate = Loadable(lazy(() => import('../pages/dashboard/DiskonDate')));
const RoleList = Loadable(lazy(() => import('../pages/dashboard/RoleList')));
const UpdateQty = Loadable(lazy(() => import('../pages/dashboard/UpdateQty')));
const Oprational = Loadable(lazy(() => import('../pages/dashboard/Oprational')));
const ListCode = Loadable(lazy(() => import('../pages/dashboard/ListCode')));
// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
